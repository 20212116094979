<template>
  <div v-if="config" class="app-container">
    <div class="countdown-wrap">
      <div class="deadline">
        <span>活动截止：</span>
        <em>{{ deadlineText }}</em>
      </div>
      <div class="countdown">
        <span>活动仅剩</span>
        <em>{{ countdownMinute }}</em>
        <span>分</span>
        <em>{{ countdownSecond }}</em>
        <span>秒</span>
      </div>
    </div>
    <div ref="containerScrollWrap" class="scroll-wrap">
      <img v-if="!config.bannerBgUrl" class="banner" src="~@/assets/images/banner.png" alt="" @click.prevent.self="() => null">
      <img v-else class="banner" :src="oss2cdn(config.bannerBgUrl)" alt="" @click.prevent.self="() => null">
      <div ref="formWrap" class="form-wrap">
        <div class="phone-item">
          <input ref="phoneText" v-model="phone" type="text" placeholder="请输入您的手机号" @focus="handleScrollIntoViewIfNeeded('phoneText')">
        </div>
        <div class="code-box">
          <div class="code-item">
            <input ref="codeText" v-model="verificationCode" type="text" placeholder="请输入验证码" @focus="handleScrollIntoViewIfNeeded('codeText')">
          </div>
          <div class="code-btn" @click="handleSendVerificationCode">{{ !count ? '获取验证码' : `${maxCount - count}s` }}</div>
        </div>
        <div class="middle-pay-btn" @click="handlePaySmallClass">立即报名</div>
      </div>
      <div class="tips">*注：抢购后须按照提示关注公众号，扫描二维码进学习小组</div>
      <div class="newest-user">
        <div class="title">最新报名客户</div>
        <div ref="scrollWrap" class="user-list">
          <div v-for="(item, index) in userList" :key="index" ref="userItem" class="user-item">
            <span>{{ item.phone }}</span>
            <em>报名成功</em>
            <strong>{{ orderTimeFormat(item.time) }}</strong>
          </div>
        </div>
      </div>
      <img v-if="!config.contentBgUrl" src="~@/assets/images/content.png" alt="" class="content" @click.prevent.self="() => null">
      <img v-else :src="oss2cdn(config.contentBgUrl)" alt="" class="content" @click.prevent.self="() => null">
    </div>
    <div v-if="!config.hideBottom" class="bottom">
      <div class="left">
        <div class="title">名额仅剩</div>
        <div class="count">
          <span>{{ balance }}</span>
          <em>人</em>
        </div>
      </div>
      <div class="right bottom-pay-btn" @click="handlePaySmallClass">立即报名</div>
    </div>
  </div>
</template>
<script>
import {Landing} from '@/network'
import moment from 'moment'
import {doubleNum, maskCode, device, oss2cdn} from '@/utils'
import MoveTo from 'moveto'
import {Toast} from 'vant'

const MAX_COUNT = 60
const defaultActivityAmount = 50
export default {
  data() {
    return {
      config: null, // 初始化隐藏底部
      price: 7,
      phone: null,
      verificationCode: null,
      userList: [],
      endTime: null,
      timeCount: 0,
      durationTimer: null,
      moveTo: null,
      codeTimer: null,
      count: 0,
      maxCount: MAX_COUNT,
      disabledCodeBtn: false,
      disabledPayBtn: false,
      outTradeNo: null, // 订单号
      localStartTime: null, // 本地时间戳起点
      nowTime: null, // 服务器时间
      channelId: null,
      oss2cdn
    }
  },
  computed: {
    deadlineText() {
      return this.endTime ? this.endTime.format('Ahh:mm').replace('AM', '上午').replace('PM', '下午') : ''
    },
    duration() {
      return this.endTime ? this.endTime.diff(moment(), 'seconds') : 0
    },
    countdownMinute() {
      return this.endTime && this.duration - this.timeCount >= 0 ? doubleNum(Math.floor((this.duration - this.timeCount) / 60)) : '00'
    },
    countdownSecond() {
      return this.endTime && this.duration - this.timeCount >= 0 ? doubleNum((this.duration - this.timeCount) % 60) : '00'
    },
    activityAmount() {
      // 活动人数
      return this.config.activityAmount || defaultActivityAmount
    },
    balance() {
      const num = this.activityAmount - this.userList.length
      return num && num > 0 ? num : 9
    }
  },
  async created() {
    // 保存订单信息，在微信支付回调redirect_url不生效时候刷新页面
    const orderInfo = window.sessionStorage.getItem('orderInfo') && JSON.parse(window.sessionStorage.getItem('orderInfo'))
    const currentTimestamp = +new Date()
    window.sessionStorage.removeItem('orderInfo')
    if (orderInfo && orderInfo.timestamp && currentTimestamp - orderInfo.timestamp < 60 * 1000) {
      this.$router.push({
        name: 'StreamPaySuccess',
        params: {outTradeNo: orderInfo.outTradeNo},
        query: {goodsId: this.$route.params.id}
      })
      return
    }
    // 加载图标
    const configLoadingToast = Toast.loading({
      message: '页面加载中...',
      forbidClick: true,
      duration: 0,
      loadingType: 'spinner'
    })
    // 页面配置信息
    const {data, nowTime} = await Landing.getLandingPageConfig(this.$route.params)
    configLoadingToast.clear()
    // 设置channelId，支付时提供各渠道指定参数
    this.channelId = data.channelId
    try {
      this.config = JSON.parse(data.presentConfig) || {}
    } catch (error) {
      console.warn(error)
    }
    this.localStartTime = +new Date()
    this.nowTime = nowTime
    const codeTimestamp = window.sessionStorage.getItem('codeTimestamp')
    this.count = codeTimestamp && new Date() - codeTimestamp < 60000 ? Math.ceil((new Date() - codeTimestamp) / 1000) : 0
    if (this.count) {
      this.phone = window.sessionStorage.getItem('phone')
      this.handleCodeTimer()
    }
    this.endTime = moment().add(1, 'hours').minutes(0)
    this.durationTimer = setInterval(() => {
      this.timeCount++
    }, 1000)
    this.userList = this.generateUserList(5)
  },
  mounted() {
    setTimeout(() => {
      this.moveTo = new MoveTo({
        duration: 5000,
        container: this.$refs.scrollWrap
      })
      this.randomPush()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.durationTimer)
    clearInterval(this.codeTimer)
  },
  methods: {
    randomPhoneNum() {
      const headerNums = [
        '139',
        '138',
        '137',
        '136',
        '135',
        '134',
        '159',
        '158',
        '157',
        '150',
        '151',
        '152',
        '188',
        '187',
        '182',
        '183',
        '184',
        '178',
        '130',
        '131',
        '132',
        '156',
        '155',
        '186',
        '185',
        '176',
        '133',
        '153',
        '189',
        '180',
        '181',
        '177'
      ]
      const headerNum = headerNums[parseInt(Math.random() * 10, 10)]
      const bodyNum = Math.random().toString().replace('0.', '').slice(0, 4)
      return headerNum + '****' + bodyNum
    },
    generateUserList(num) {
      return Array.from(Array(num), () => {
        return {
          phone: this.randomPhoneNum(),
          time: 1
        }
      })
    },
    randomPush() {
      const randomNum = Math.ceil(Math.random() * 10)
      setTimeout(() => {
        // 更新时间
        this.userList = this.userList.map(o => ({...o, time: (o.time += randomNum)})).concat(this.generateUserList(Math.ceil(Math.random() * 4)))
        setTimeout(() => {
          const target = this.$refs.userItem.pop()
          target && this.moveTo.move(target)
        }, 100)
        if (this.activityAmount - this.userList.length < 10) return
        this.randomPush()
      }, randomNum * 1000)
    },
    orderTimeFormat(time) {
      return time < 60 ? `${time}秒前` : `${Math.floor(time / 60)}分钟前`
    },
    async handleSendVerificationCode() {
      if (this.count > 0) return Toast('短信已发送')
      if (!/^1[3-9]\d{9}$/.test(this.phone)) return Toast('请输入正确的手机号')
      if (this.disabledCodeBtn) return Toast('短信正在发送')
      this.disabledCodeBtn = true
      const {code, message} = await Landing.sendVerificationCode({
        maskCode: maskCode(this.nowTime, this.localStartTime),
        phone: this.phone
      })
      this.disabledCodeBtn = false
      if (code) return Toast(message)
      if (!code) {
        window.sessionStorage.setItem('phone', this.phone)
        window.sessionStorage.setItem('codeTimestamp', +new Date())
        Toast('短信发送成功')
        this.handleCodeTimer()
      }
    },
    handleCodeTimer() {
      this.count++
      this.codeTimer = setTimeout(() => {
        if (this.count < MAX_COUNT) return this.handleCodeTimer()
        this.count = 0
        window.sessionStorage.removeItem('codeTimestamp')
        window.sessionStorage.removeItem('phone')
      }, 1000)
    },
    async handlePaySmallClass() {
      this.formPositionCheck()
      if (!this.phone) return Toast('请输入手机号')
      if (!this.verificationCode) return Toast('请输入验证码')
      if (this.disabledPayBtn) return Toast('正在唤起微信支付')
      this.disabledPayBtn = true
      const {data, code, message} = await Landing.paySmallClass({
        goodsId: this.$route.params.id,
        phone: this.phone,
        code: this.verificationCode,
        callback: window.location.href,
        channelId: this.channelId,
        device: device()
      })
      if (code) {
        this.disabledPayBtn = false
        return Toast(message)
      }
      this.outTradeNo = data.outTradeNo
      const {href} = this.$router.resolve({
        name: 'StreamPaySuccess',
        params: {outTradeNo: this.outTradeNo},
        query: {goodsId: this.$route.params.id}
      })
      const redirectUrl = `${window.location.origin}${href}`
      window.sessionStorage.setItem(
        'orderInfo',
        JSON.stringify({
          timestamp: +new Date(),
          outTradeNo: this.outTradeNo
        })
      )
      this.disabledPayBtn = false
      if (!data.mwebUrl) {
        location.href = redirectUrl
      } else {
        location.href = `${data.mwebUrl}&redirect_url=${encodeURIComponent(redirectUrl)}`
      }
    },
    handleScrollIntoViewIfNeeded(refName) {
      this.$refs[refName].scrollIntoViewIfNeeded()
    },
    formPositionCheck() {
      this.$refs.phoneText.focus()
      const rect = this.$refs.formWrap.getBoundingClientRect()
      const maxHeight = window.screen.height - rect.height
      if (rect.top < 0 || rect.top > maxHeight) {
        const moveto = new MoveTo({
          tolerance: maxHeight / 2,
          duration: 500,
          container: this.$refs.containerScrollWrap
        })
        moveto.move(this.$refs.formWrap)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.countdown-wrap {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  background: #ff6b03;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
  padding: 0 9px;
  .deadline {
    width: auto;
    height: 15px;
    span {
      line-height: 15px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    em {
      line-height: 15px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      font-style: normal;
      color: #ffffff;
    }
  }
  .countdown {
    width: auto;
    height: 15px;
    vertical-align: text-bottom;
    span {
      width: auto;
      height: 15px;
      line-height: 15px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    em {
      display: inline-block;
      width: 32px;
      height: 15px;
      text-align: center;
      line-height: 15px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      font-style: normal;
      color: #ffffff;
    }
  }
}
.scroll-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 46px;
  padding-bottom: 57px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .banner {
    margin-bottom: 22px;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
  .form-wrap {
    width: 342px;
    height: 185px;
    margin: 0 auto 26px;
    box-sizing: border-box;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url('~@/assets/images/form-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 7px 3px 18px 0px rgba(9, 4, 111, 0.15);
    .phone-item {
      width: 281px;
      height: 36px;
      background: #f0eeef;
      border-radius: 18px;
      box-sizing: border-box;
      padding: 0 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      input {
        width: 100%;
        line-height: 16px;
        font-size: 14px;
        font-family: PingFang SC;
        background: #f0eeef;
        font-weight: 400;
        color: #7b7b7b;
        border: none;
        -webkit-appearance: none;
        outline: none;
        &::placeholder {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #7b7b7b;
          line-height: 16px;
        }
      }
    }
    .code-box {
      width: 281px;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
    .code-item {
      @extend .phone-item;
      width: 165px;
      margin-bottom: 0px;
    }
    .code-btn {
      width: 104px;
      height: 36px;
      background: #fff4ef;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ff6f17;
      line-height: 11px;
    }
    .middle-pay-btn {
      width: 281px;
      height: 45px;
      background: #ff6f17;
      box-shadow: 0px 3px 7px 0px rgba(254, 73, 20, 0.3);
      border-radius: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .tips {
    width: 100%;
    height: 13px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #e50405;
    line-height: 13px;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 38px;
  }
  .newest-user {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    .title {
      width: 100%;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #1d1d1d;
      line-height: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    .user-list {
      width: 230px;
      height: 125px;
      overflow: auto;
      margin: 0 auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .user-item {
        width: 100%;
        height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        span {
          width: 116px;
          line-height: 13px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #85898e;
        }
        em {
          width: 59px;
          line-height: 13px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #85898e;
          font-style: normal;
        }
        strong {
          width: 58px;
          line-height: 13px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #85898e;
          text-align: right;
        }
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99999;
  .left {
    width: 155px;
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 12px;
    .title {
      width: auto;
      height: 12px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 12px;
      margin-bottom: 12px;
    }
    .count {
      width: auto;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: auto;
        line-height: 21px;
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #f22609;
        margin-right: 5px;
      }
      em {
        width: auto;
        line-height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        font-style: normal;
      }
    }
  }
  .right {
    width: 220px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 24px;
    background-image: url('~@/assets/images/pay-btn-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
